import React, { AnchorHTMLAttributes } from 'react';
import { useEffect,useState } from 'react';

import ManPng  from'../src/assets/imgs/indomay32.jpg'
import PencilCase from  '../src/assets/imgs/pencil-case.svg'
import Responsive from '../src/assets/imgs/responsive.svg'
import toolbox from '..//src/assets/imgs/toolbox.svg'
import analytics from '../src/assets/imgs/analytics.svg'
import folio1 from '../src/assets/imgs/folio-1.jpg'
import folio2 from '../src/assets/imgs/folio-2.jpg'
import folio3 from '../src/assets/imgs/folio-3.jpg'
import ajglowdecor from '../src/assets/imgs/asir-glow-decor-favicon-color.png'
import profitalk from '../src/assets/imgs/profittalk.png'
import axios from 'axios';

import './App.css';

function App() {

   
        const [isActive, setIsActive] = useState(false);
        useEffect(() => {
            const handleScroll = (event:Event) => {
              const target = event.target as HTMLAnchorElement; // Typecasting to HTMLAnchorElement
        
              if (target.hash !== "") {
                event.preventDefault();
        
                const hash = target.hash;
                const element = document.querySelector(hash) as HTMLElement;
        
                if (element) {
                  window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                  });
        
                  window.location.hash = hash;
                }
              }
            };
        
            const navLinks = document.querySelectorAll('.navbar .nav-link');
            navLinks.forEach((link) => {
              link.addEventListener('click', handleScroll);
            });
        
            return () => {
              navLinks.forEach((link) => {
                link.removeEventListener('click', handleScroll);
              });
            };
          }, []);
        
          const toggleNavbar = () => {
            setIsActive(!isActive);
          };

          const [isAtTop, setIsAtTop] = useState(true);

          useEffect(() => {
            const handleScroll = () => {
              if (window.scrollY === 0) {
                setIsAtTop(true);
              } else {
                setIsAtTop(false);
              }
            };
        
            window.addEventListener('scroll', handleScroll);
        
            // Clean up the event listener on component unmount
            return () => {
              window.removeEventListener('scroll', handleScroll);
            };
          }, []);
      

          const [YourName,SetYourName]=useState('')
          const [YourEmail,SetYouremail]=useState('')
          const [YourMessage,SetYourMessage]=useState('')
          const [ErrorMessage,SetErrorMessage]=useState('')
          const [SuccessMessage,SetSuccessMessage]=useState('')
          const [loader,Setloader]=useState(false)



          const HandleSubmission=()=>{

            

            let err=0;
            SetErrorMessage('')
            SetSuccessMessage('')
            Setloader(true)

            if(YourName===undefined || YourName===null || YourName==='' )
            {
                err=1;
                SetErrorMessage('*Please enter your name*')
                Setloader(false)
                return false
            }
            if(YourEmail===undefined || YourEmail===null || YourEmail==='' )
                {
                    err=1;
                    SetErrorMessage('*Please enter your email address*')
                    Setloader(false)
                    return false
                }
                if(YourMessage===undefined || YourMessage===null || YourMessage==='' )
                    {
                        err=1;
                        SetErrorMessage('*Please enter your message*')
                        Setloader(false)
                        return false
                    }
                    

                    if(err===0)
                    {
                    
                        const response=axios.post('https://vercelpersonalportfoliobackend.vercel.app/send-email',{
                            name:YourName,
                            email:YourEmail,
                            message:YourMessage
                        }).then(()=>{
                            Setloader(false)
                            SetYourMessage('')
                            SetYourName('')
                            SetYouremail('')
                            SetSuccessMessage('Thanks for contacting. I will get back to you shortly!')
                        }).catch((err)=>{
                            Setloader(false)
                            SetYourMessage('')
                            SetYourName('')
                            SetYouremail('')
                            SetErrorMessage('Something went wrong. Please try again later')


                        })
                    
                        
                    
                    }
                   
                    

          }

          function greetBasedOnTime() {
            const currentTime = new Date();
            const hours = currentTime.getHours();
          
            if (hours < 12) {
             return("Good morning!");
            } else if (hours >= 12 && hours < 18) {
                return("Good afternoon!");
            } else {
                return("Good evening!");
            }
          }
          
          
     

          

          const HandleProjectClick = (e: React.MouseEvent<HTMLElement>) => {
            // Get the target element that was clicked
            const target = e.target as HTMLElement;
          
            // Find the closest ancestor with the class 'target-class'
            const closestElement = target.closest('.project-top-node') as HTMLElement | null;
          
            if (closestElement) {
              // Fetch the data-href attribute from the closest element
              const href = closestElement.getAttribute('data-href');
          
              if (href) {

                window.open(href,'_blank')
              
              }
            }
          };
    
  return (
    <div className="App">

        
        <nav data-spy="affix" data-offset-top="20" className={`${isActive ? 'is-active' : ''} ${isAtTop ? 'affix-top' : 'affix'} custom-navbar`} onClick={toggleNavbar}>
        <div className="container">
            <a className="logo" href="#">{greetBasedOnTime()}</a>
            <ul className={isActive ? 'show nav' : 'nav'}>
                <li className="item">
                    <a className="link" href="#home">Home</a>
                </li>
                <li className="item">
                    <a className="link" href="#about">About</a>
                </li>
                <li className="item">
                    <a className="link" href="#portfolio">Portfolio</a>
                </li>
                <li className="item">
                    <a className="link" href="#projects">Projects</a>
                </li>
                <li className="item">
                    <a className="link" href="#technology">Technology</a>
                </li>
              
                <li className="item">
                    <a className="link" href="#contact">Contact</a>
                </li>
                
            </ul>
            <a id="nav-toggle" className={"hamburger hamburger--elastic " +`${isActive ? 'is-active' : ''}`}>
                <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                </div>
            </a>
        </div>
    </nav>

    <header id="home" className="header">
        <div className="overlay"></div>
        <div className="header-content container">
            <h1 className="header-title">
                <span className="up">HI!</span>
                <span className="down">I am John</span>
            </h1>
            <p className="header-subtitle">Full Stack Web Developer</p>

           <a href='#contact'><button className="btn btn-primary">Contact</button></a> 
        </div>
    </header>

    <section className="section pt-0" id="about">
    
        <div className="container text-center">
     
            <div className="about">
                <div className="about-img-holder">
                    <img src={ManPng} className="about-img"
                        alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                </div>
                <div className="about-caption">
                    <p className="section-subtitle">Who Am I ?</p>
                    <h2 className="section-title mb-3">About Me</h2>
                    <p>
                    Experienced Full Stack Web Developer with years of experience in the software industry, adept at creating dynamic and user-friendly web solutions. Strong background in delivering high-quality projects, with a deep understanding of web development principles and trends. Known for problem-solving skills and a commitment to staying ahead of the curve. Passionate about driving innovation and delivering impactful web applications.
                    </p>
                   <a href='https://1drv.ms/b/c/1b7563c91c53c8f2/EYdPK9rIfKRKin4q9yF4v4sBiaxblQhiInPr9LZCRTP1Qg?e=muvZ7g' target='_blank'><button className="btn-rounded btn btn-outline-primary mt-4">Download Resume</button></a> 
                </div>
            </div>
        </div>
    </section>

    <section className="section" id="portfolio">
        <div className="container text-center">
            <p className="section-subtitle">What I Do ?</p>
            <h6 className="section-title mb-6">Portfolio</h6>
           
            <div className="row">

            <div className="col-md-6 col-lg-3">
                    <div className="service-card">
                        <div className="body">
                            <img src={toolbox}
                                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page"
                                className="icon" />
                            <h6 className="title">Web Applications</h6>
                            <p className="subtitle">I’ve built dynamic web applications using Node.js and React, focusing on seamless user experiences and scalability</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="service-card">
                        <div className="body">
                            <img src={analytics}
                                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page"
                                className="icon" />
                            <h6 className="title">E-commerce Solutions</h6>
                            <p className="subtitle">I’ve developed and managed e-commerce sites with WooCommerce and WordPress, enhancing user engagement and driving sales</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="service-card">
                        <div className="body">
                            <img src={PencilCase}
                                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page"
                                className="icon"/>
                            <h6 className="title"> Static Websites</h6>
                            <p className="subtitle">I have experience developing static websites using WordPress, ensuring responsive and user-friendly designs.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="service-card">
                        <div className="body">
                            <img src={Responsive}
                                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page"
                                className="icon" />
                            <h6 className="title">HTML Templates</h6>
                            <p className="subtitle">I have crafted custom HTML templates with clean code and cross-browser compatibility.</p>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
    </section>

    <section className="section" id="projects">
        <div className="container text-center">
            <p className="section-subtitle">What I Did ?</p>
            <h6 className="section-title mb-6">Own Projects</h6>
           
            <div className="row">

            <div className="col-md-6 col-lg-3 project-top-node" style={{cursor:'pointer'}} data-href={'https://dev-asir-glow-decor.pantheonsite.io/'} onClick={(e)=>HandleProjectClick(e)}>
                    <div className="service-card aj-light-decor-card">
                        <div className="body">
                            <img src={ajglowdecor}
                                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page"
                                className="icon" />
                            <h6 className="title">AJ Glow Decor</h6>
                            <p className="subtitle" >I’ve built this ecommerce application using wordpress and woocommerce and integrated a razorpay payment gateway inside this application</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3 project-top-node" style={{cursor:'pointer'}} data-href={'https://profittalkfrontend.vercel.app/'} onClick={(e)=>HandleProjectClick(e)}>
                    <div className="service-card profit-talk-card">
                        <div className="body">
                            <img src={profitalk}
                                alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page"
                                className="icon" />
                            <h6 className="title">Profit Talk</h6>
                            <p className="subtitle" >I’ve built ProfitTalk, a SaaS application using Node.js, React, MongoDB Atlas, and Vercel, with Razorpay payment gateway integrated. Users can buy connects, provide expertise via video calls, and get paid!</p>
                        </div>
                    </div>
                </div>
          

              
            </div>
        </div>
    </section>

    <section className="section" id="technology">
        <div className="container text-center">
            <p className="section-subtitle">What I Know ?</p>
            <h6 className="section-title mb-6">Technology</h6>
     
            <div className="row">
                <div className="col-md-4">
                    <a href="" onClick={(e)=>{e.preventDefault()}} className="portfolio-card">
                        <img src={folio1} className="portfolio-card-img"
                            alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                        <span className="portfolio-card-overlay">
                            <span className="portfolio-card-caption">
                                <h4>Web Designing</h4>
                                    <p className="font-weight-normal">HTML | CSS </p>
                            </span>
                        </span>
                    </a>
                </div>
                <div className="col-md-4">
                    <a href="" onClick={(e)=>{e.preventDefault()}} className="portfolio-card">
                        <img className="portfolio-card-img img-responsive rounded" src={folio2}
                            alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                        <span className="portfolio-card-overlay">
                            <span className="portfolio-card-caption">
                                <h4>Web Development</h4>
                                    <p className="font-weight-normal">Node | React | Wordpress</p>
                            </span>
                        </span>
                    </a>
                </div>
                <div className="col-md-4">
                    <a href="" onClick={(e)=>{e.preventDefault()}} className="portfolio-card">
                        <img className="portfolio-card-img img-responsive rounded" src={folio3}
                            alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" />
                        <span className="portfolio-card-overlay">
                            <span className="portfolio-card-caption">
                                <h4>Deployment</h4>
                                    <p className="font-weight-normal">Git | Jenkins | Cloud Platforms</p>
                            </span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </section> 

    <section className="section-sm bg-primary">
    
        <div className="container text-center text-sm-left">
          
            <div className="row align-items-center">
                <div className="col-sm offset-md-1 mb-4 mb-md-0">
                    <h6 className="title text-light">Want to work with me?</h6>
                    <p className="m-0 text-light">Always feel Free to Contact & Hire me</p>
                </div>
                <div className="col-sm offset-sm-2 offset-md-3">
                    <a href='#contact'><button className="btn btn-lg my-font btn-light rounded">Contact</button></a>
                </div>
            </div>
        </div> 
    </section>

    <section className="section" id="contact">
        <div className="container text-center">
            <p className="section-subtitle">How can you communicate?</p>
            <h6 className="section-title mb-5">Contact Me</h6>
      
            <form action="" className="contact-form col-md-10 col-lg-8 m-auto">
                <div className="form-row">
                    <div className="form-group col-sm-6">
                        <input type="text" size={50} className="form-control" placeholder="Your Name" value={YourName}  onChange={(e)=>{SetYourName(e.target.value)}} required />
                    </div>
                    <div className="form-group col-sm-6">
                        <input type="email" className="form-control" value={YourEmail} placeholder="Enter Email" required  onChange={(e)=>{SetYouremail(e.target.value)}} />
                    </div>
                    <div className="form-group col-sm-12">
                        <textarea name="comment" id="comment" value={YourMessage} rows={6} className="form-control"
                            placeholder="Write Something" onChange={(e)=>{SetYourMessage(e.target.value)}}></textarea>
                    </div>
                    <div className="form-group col-sm-12 mt-3" style={{color:'red'}}>
                      {ErrorMessage}
                    </div>
                    <div className="form-group col-sm-12 mt-3" style={{color:'green'}}>
                      {SuccessMessage}
                    </div>

                    
                    <div className="form-group col-sm-12 mt-3">
                        <input type="button" value="Send Message" className="btn btn-outline-primary rounded" onClick={HandleSubmission} />

                        {loader===true?<i className="fa fa-circle-o-notch fa-spin" style={{fontSize:'24px'}}></i>: null }

                    </div>
                </div>
            </form>
        </div>
    </section>

    <div className="container">
        <footer className="footer">
            <p className="mb-0">&#169;{' 2024  '} All rights reserved

                 <a
                    href="tel:+918825413924"> |+91 8825413924</a> 
                       <a
                    href="mailto:johnjebaraj361@gmail.com"> | johnjebaraj361@gmail.com</a> 
                       
            </p>
            <div className="social-links text-right m-auto ml-sm-auto">
                <a  target='_blank' href="https://www.facebook.com/people/John-Jebaraj-A/pfbid02XwQZAaH2NL6vezLbVLTt5PrfuLXMomBoZ6YKs5vh7KW3VFDNyrxsNMGh3eLrusrjl/" className="link"><i className="ti-facebook"></i></a>
                <a target='_blank' href="https://www.instagram.com/johnjebaraj361/" className="link"><i className="ti-instagram"></i></a>
                <a target='_blank' href="https://linkedin.com/in/john-jebaraj-245219160" className="link"><i className="ti-linkedin"></i></a>
     
            </div>
        </footer>
    </div> 


    </div>
  );
}

export default App;
